import { FaShareSquare } from "react-icons/fa";
import { mainYellow5Color } from "../../../const/colors";

export function TopArrowBar({
  prevFunc,
  nextFunc,
  shareFunc,
}: {
  prevFunc: (() => void) | null;
  nextFunc: (() => void) | null;
  shareFunc?: (() => void) | null;
}) {
  return (
    <div className="h-[50px] w-full flex items-center">
      {prevFunc !== null ? (
        <img
          className="h-[36px] p-[8px] hover:cursor-pointer"
          src="/images/icon_back_arrow.png"
          alt=""
          onClick={prevFunc}
        />
      ) : (
        <div className="w-[52px]"></div>
      )}
      <div className="flex-1 flex justify-center items-center">
        골프채 추천 설문
      </div>
      <div className="w-[52px] flex justify-center items-center">
        {shareFunc !== null && shareFunc !== undefined ? (
          <FaShareSquare
            className="hover:cursor-pointer"
            size={25}
            color={mainYellow5Color}
            onClick={shareFunc}
          />
        ) : null}
      </div>
    </div>
  );
}
