import { useEffect } from "react";
import { Link } from "react-router-dom";

export function HomePageV2() {
  useEffect(() => {
    window.location.href =
      "https://roan-silver-eb8.notion.site/Special-Tour-149098d9401d802f84a6f985fe507970";
  }, []);
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center gap-4">
      <div className="font-800 text-[30px] mb-5">스페셜 투어</div>
      <a href="https://roan-silver-eb8.notion.site/Special-Tour-149098d9401d802f84a6f985fe507970">
        <div className="w-[280px] h-[50px] rounded-full border-red-300 border flex flex-row items-center justify-center gap-3">
          <img
            src="/images/special_logo.png"
            alt=""
            height="30px"
            width="30px"
          />
          <div>스페셜 골프 투어 (new)</div>
        </div>
      </a>
      <Link to="/golf">
        <div className="w-[280px] h-[50px] rounded-full border-red-300 border flex flex-row items-center justify-center gap-3">
          <img
            src="/images/special_logo.png"
            alt=""
            height="30px"
            width="30px"
          />
          <div>스페셜 골프 투어 (old)</div>
        </div>
      </Link>
      <Link to="/used">
        <div className="w-[280px] h-[50px] rounded-full border-blue-300 border flex flex-row items-center justify-center gap-3">
          <div>⛳🏒🏑</div>
          <div>중고 파트너</div>
        </div>
      </Link>
    </div>
  );
}
