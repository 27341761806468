import { FirebaseTypeChange } from "./utils/FirebaseTypeChange";

export class Club {
  name: string;
  type: string;
  shaftAngle: number;
  flex: string;
  imgUrl: string;
  composition: string;
  price: number;
  priceUnit: string;

  constructor(
    name: string,
    type: string,
    shaftAngle: number,
    flex: string,
    imgUrl: string,
    composition: string,
    price: number,
    priceUnit: string
  ) {
    this.name = name;
    this.type = type;
    this.shaftAngle = shaftAngle;
    this.flex = flex;
    this.imgUrl = imgUrl;
    this.composition = composition;
    this.price = price;
    this.priceUnit = priceUnit;
  }

  static fromData(v: any): Club {
    try {
      const name = FirebaseTypeChange.stringFromData(v["name"]);
      const type = FirebaseTypeChange.stringFromData(v["type"]);
      const shaftAngle = FirebaseTypeChange.numberFromData(v["shaftAngle"]);
      const flex = FirebaseTypeChange.stringFromData(v["flex"]);
      const imgUrl = FirebaseTypeChange.stringFromData(v["imgUrl"]);
      const composition = FirebaseTypeChange.stringFromData(v["composition"]);
      const price = FirebaseTypeChange.numberFromData(v["price"]);
      const priceUnit = FirebaseTypeChange.stringFromData(v["priceUnit"]);

      return new Club(
        name,
        type,
        shaftAngle,
        flex,
        imgUrl,
        composition,
        price,
        priceUnit
      );
    } catch (e) {
      console.log(`[Club] fromData error: ${e}`);
      return Club.empty;
    }
  }

  static get empty(): Club {
    return new Club("", "", 0, "", "", "", 0, "");
  }

  get clone(): Club {
    return new Club(
      this.name,
      this.type,
      this.shaftAngle,
      this.flex,
      this.imgUrl,
      this.composition,
      this.price,
      this.priceUnit
    );
  }
}
