export const mainWhiteColor: string = "#fffffb";
export const mainGray1Color: string = "#e8e8e8";
export const mainGray2Color: string = "#bcbcba";
export const mainGray3Color: string = "#908f8d";
export const mainGray4Color: string = "#636260";
export const mainGray5Color: string = "#4d4c49";
export const mainGray6Color: string = "#373632";
export const mainBlackColor: string = "#21201c";

export const mainYellow1Color: string = "#fef550";
export const mainYellow2Color: string = "#fef773";
export const mainYellow3Color: string = "#fef996";
export const mainYellow4Color: string = "#fefbb9";
export const mainYellow5Color: string = "#fefddb";
