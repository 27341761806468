import React, { ReactNode, useState } from "react";
import { HomePageV1 } from "./page/v1/HomePageV1";
import { HomePageV2 } from "./page/v2/HomePageV2";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import V2_Used from "./page/v2/used/V2_Used";
import V2_Survey from "./page/v2/used/survey/V2_Survey";
import ClubInfo from "./page/v2/used/ClubInfo";
import NotFoundScene from "./page/NotFoundScene";
import { Survey_main } from "./page/v2/used/survey/Survey_main";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePageV2 />}></Route>
        <Route path="/golf" element={<HomePageV1 />}></Route>
        <Route path="/used" element={<V2_Used />}></Route>
        <Route path="/used/:code" element={<ClubInfo />}></Route>
        <Route path="/used/survey" element={<Survey_main />}></Route>
        <Route path="*" element={<NotFoundScene />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

// <div className="mx-auto max-w-[500px] h-screen flex flex-col items-center">
//   <div className="w-screen h-screen flex flex-col items-center">
//   <HomePageV1 />
//   <HomePageV2 />
// </div>

export default App;
