import { Link } from "react-router-dom";

export function HeaderV2({ backRoot }: { backRoot: string | null }) {
  if (backRoot !== null) {
    return (
      <Link to={backRoot}>
        <div className="h-[50px] w-full flex items-center justify-center">
          <div>⛳🏒🏑 중고 클럽</div>
        </div>
      </Link>
    );
  }
  return (
    <Link to="/">
      <div className="h-[50px] w-full flex items-center justify-center gap-2">
        <img src="/images/special_logo.png" alt="" height="30px" width="30px" />
        <div>스페셜 투어</div>
      </div>
    </Link>
  );
}
