import { mainGray1Color, mainYellow1Color } from "../../../const/colors";

export function TopProcessBar({
  nowIndex,
  subNum, // 1 ~ n
  maxSubNum, // n
  cutomLen,
}: {
  nowIndex: number;
  subNum: number;
  maxSubNum: number;
  cutomLen?: number;
}) {
  const processList: number[] = Array.from(
    Array(cutomLen === undefined ? 7 : cutomLen).keys()
  );
  return (
    <div className="h-[80px] w-full px-[20px] flex items-center justify-center">
      <div
        style={{
          width:
            cutomLen === undefined
              ? "50%"
              : `${Math.floor((50 * cutomLen!) / 7)}%`,
        }}
        className="w-[50%] flex items-center justify-center"
      >
        {processList.map((p) => {
          if (p % 2 == 0) {
            return (
              <div
                key={p}
                style={{
                  backgroundColor:
                    Math.floor(p / 2) <= nowIndex
                      ? mainYellow1Color
                      : mainGray1Color,
                  boxShadow:
                    Math.floor(p / 2) === nowIndex
                      ? `0px 0px 15px ${mainYellow1Color}`
                      : "",
                }}
                className="w-[15px] h-[15px] rounded-full"
              />
            );
          }
          if (Math.floor(p / 2) === nowIndex && subNum !== 0 && maxSubNum > 0) {
            return (
              <div key={p} className="flex-1 flex">
                {Array.from(Array(maxSubNum + 1).keys()).map((i) => {
                  return (
                    <div
                      key={`line_${i}`}
                      style={{
                        backgroundColor:
                          i <= subNum - 1 ? mainYellow1Color : mainGray1Color,
                      }}
                      className="flex-1 h-[2px]"
                    ></div>
                  );
                })}
              </div>
            );
          }
          return (
            <div
              key={`line_${p}`}
              style={{
                backgroundColor:
                  Math.floor(p / 2) <= nowIndex - 1
                    ? mainYellow1Color
                    : mainGray1Color,
              }}
              className="flex-1 h-[2px]"
            ></div>
          );
        })}
      </div>
    </div>
  );
}
