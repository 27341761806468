import { FirebaseTypeChange } from "./utils/FirebaseTypeChange";

export class UserInfo {
  name: string;
  height: number;
  weight: number;
  age: number;
  gender: string;

  constructor(
    name: string,
    height: number,
    weight: number,
    age: number,
    gender: string
  ) {
    this.name = name;
    this.height = height;
    this.weight = weight;
    this.age = age;
    this.gender = gender;
  }

  static fromData(data: any): UserInfo {
    try {
      const name = FirebaseTypeChange.stringFromData(data["name"]);
      const height = FirebaseTypeChange.numberFromData(data["height"]);
      const weight = FirebaseTypeChange.numberFromData(data["weight"]);
      const age = FirebaseTypeChange.numberFromData(data["age"]);
      const gender = FirebaseTypeChange.stringFromData(data["gender"]);
      return new UserInfo(name, height, weight, age, gender);
    } catch (e) {
      console.log(`[UserInfo] fromData e:${e}`);
      return UserInfo.empty;
    }
  }

  get clone(): UserInfo {
    return new UserInfo(
      this.name,
      this.height,
      this.weight,
      this.age,
      this.gender
    );
  }

  get toMap() {
    return {
      name: this.name,
      height: this.height,
      weight: this.weight,
      age: this.age,
      gender: this.gender,
    };
  }

  static get empty() {
    return new UserInfo("", 0, 0, 0, "");
  }
}
