import { useState } from "react";
import { UserInfo } from "../../../../domain/UserInfo.model";
import V2_Survey from "./V2_Survey";
import { HeaderV2 } from "../../HeaderV2";

export function Survey_main() {
  const [userInfo, setUserInfo] = useState<UserInfo>(UserInfo.empty);

  return (
    <div className="mx-auto max-w-[500px] h-screen flex flex-col items-center">
      <HeaderV2 backRoot={"/used"} />
      <div className="flex-1 w-full h-full">
        <V2_Survey
          goQuestionFun={() => {}}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
      </div>
    </div>
  );
}
