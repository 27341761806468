import { useState } from "react";
import { UserInfo } from "../../../../domain/UserInfo.model";
import { TopArrowBar } from "../../components/TopArrowBar";
import { TopProcessBar } from "../../components/TopProcessBar";
import { BasicButton } from "../../components/BasicButton";
import {
  mainBlackColor,
  mainGray1Color,
  mainGray4Color,
  mainGray5Color,
} from "../../../../const/colors";
import { TextField } from "@mui/material";

const userInfoEditStatuskeys = {
  start: "start",
  name: "name",
  height: "height",
  weight: "weight",
  age: "age",
  gender: "gender",
} as const;

type UserEditStatusUnion =
  (typeof userInfoEditStatuskeys)[keyof typeof userInfoEditStatuskeys];

export default function V2_Survey({
  goQuestionFun,
  userInfo,
  setUserInfo,
}: {
  goQuestionFun: () => void;
  userInfo: UserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
}) {
  const [currentStatus, setcurrentStatus] = useState<UserEditStatusUnion>(
    userInfo.gender === ""
      ? userInfoEditStatuskeys.start
      : userInfoEditStatuskeys.gender
  );

  const clickPrevBtn = () => {
    const nowIndex = Object.keys(userInfoEditStatuskeys).indexOf(currentStatus);
    if (nowIndex > 0) {
      const keyV = Object.keys(userInfoEditStatuskeys)[nowIndex - 1];
      setcurrentStatus(keyV as UserEditStatusUnion);
    } else {
      console.log("user setting start!!");
    }
  };
  const clickNextBtn = () => {
    if (hasValue()) {
      const nowIndex = Object.keys(userInfoEditStatuskeys).indexOf(
        currentStatus
      );
      if (nowIndex < Object.keys(userInfoEditStatuskeys).length - 1) {
        const keyV = Object.keys(userInfoEditStatuskeys)[nowIndex + 1];
        setcurrentStatus(keyV as UserEditStatusUnion);
      } else {
        goQuestionFun();
      }
    } else {
      alert("값을 입력/선택 해주세요.");
    }
  };
  const hasValue = (): boolean => {
    const nowIndex = Object.keys(userInfoEditStatuskeys).indexOf(currentStatus);
    switch (nowIndex) {
      case 0:
        return true;
      case 1:
        return userInfo.name !== "";
      case 2:
        return userInfo.height !== 0;
      case 3:
        return userInfo.weight !== 0;
      case 4:
        return userInfo.age !== 0;
      case 5:
        return userInfo.gender !== "";
    }
    return false;
  };
  const processSubNum = () => {
    return Object.keys(userInfoEditStatuskeys).indexOf(currentStatus);
  };
  const processMaxSubNum = () => {
    return Object.keys(userInfoEditStatuskeys).length - 1;
  };

  return (
    <div className={`w-full h-full flex flex-col items-center px-[20px]`}>
      {currentStatus !== "start" && (
        <TopArrowBar prevFunc={clickPrevBtn} nextFunc={() => {}} />
      )}
      {currentStatus !== "start" && (
        <TopProcessBar
          nowIndex={0}
          subNum={processSubNum()}
          maxSubNum={processMaxSubNum()}
        />
      )}
      <div className="flex-1 h-full w-full flex flex-col items-start">
        <MainQuestionText status={currentStatus} />
        <SubQuestionText status={currentStatus} />
        {currentStatus === "start" ? (
          <HomeStartImg />
        ) : (
          <div className="flex-1  h-full w-full flex  flex-col justify-start">
            <SelectionPart
              status={currentStatus}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              clickNextBtn={clickNextBtn}
            />
          </div>
        )}
        <BasicButton
          text={currentStatus !== "start" ? "다음으로" : "설문 시작하기"}
          onClick={clickNextBtn}
        />
      </div>
    </div>
  );
}
function HomeStartImg() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(images/first_home_image.png)`,
        backgroundSize: "90%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="flex-1 self-center"
    ></div>
  );
}

function MainQuestionText({ status }: { status: UserEditStatusUnion }) {
  return (
    <div
      style={{
        color: mainBlackColor,
      }}
      className="mt-[50px] mb-[10px] w-full whitespace-pre-wrap items-center leading-relaxed text-[24px] font-600"
    >
      {status === "start" && "중고 골프클럽 추천"}
      {status === "name" && "이름을 입력해주세요."}
      {status === "height" && "키를 입력해주세요."}
      {status === "weight" && "무게를 입력해주세요."}
      {status === "age" && "나이를 입력해주세요."}
      {status === "gender" && "성별을 선택해주세요."}
    </div>
  );
}

function SubQuestionText({ status }: { status: UserEditStatusUnion }) {
  return (
    <div
      style={{
        color: mainGray4Color,
      }}
      className="mt-[20px] mb-[10px] w-full whitespace-pre-wrap items-start leading-relaxed text-[16px] font-normal"
    >
      {status === "start" &&
        "올바른 중고 골프채 선정을 위해\n개인별 필요 제품을 알려드려요."}
      {status === "name" && ""}
      {status === "height" && ""}
      {status === "weight" && ""}
      {status === "age" && ""}
      {status === "gender" && ""}
    </div>
  );
}

function SelectionPart({
  status,
  userInfo,
  setUserInfo,
  clickNextBtn,
}: {
  status: UserEditStatusUnion;
  userInfo: UserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
  clickNextBtn: () => void;
}) {
  const setNameFunc = (name: string) => {
    setUserInfo((prev) => {
      prev.name = name;
      return prev.clone;
    });
  };
  const setHeightFunc = (h: number) => {
    setUserInfo((prev) => {
      prev.height = h;
      return prev.clone;
    });
  };
  const setWeightFunc = (w: number) => {
    setUserInfo((prev) => {
      prev.weight = w;
      return prev.clone;
    });
  };
  const setAgeFunc = (a: number) => {
    setUserInfo((prev) => {
      prev.age = a;
      return prev.clone;
    });
  };
  const setGenderFunc = (g: string) => {
    setUserInfo((prev) => {
      prev.gender = g;
      return prev.clone;
    });
  };
  return (
    <>
      {status === "name" && (
        <EditStringPart
          value={userInfo.name}
          setValue={setNameFunc}
          clickNextBtn={clickNextBtn}
        />
      )}
      {status === "height" && (
        <EditNumberPart
          value={userInfo.height}
          setValue={setHeightFunc}
          unit="cm"
          clickNextBtn={clickNextBtn}
        />
      )}
      {status === "weight" && (
        <EditNumberPart
          value={userInfo.weight}
          setValue={setWeightFunc}
          unit="kg"
          clickNextBtn={clickNextBtn}
        />
      )}
      {status === "age" && (
        <EditNumberPart
          value={userInfo.age}
          setValue={setAgeFunc}
          unit=""
          clickNextBtn={clickNextBtn}
        />
      )}
      {status === "gender" && (
        <SelGenderPart
          value={userInfo.gender}
          setValue={setGenderFunc}
          clickNextBtn={clickNextBtn}
        />
      )}
    </>
  );
}

function SelGenderPart({
  value,
  setValue,
  clickNextBtn,
}: {
  value: string;
  setValue: (v: string) => void;
  clickNextBtn: () => void;
}) {
  return (
    <div className="w-full flex justify-between">
      <img
        className="hover:cursor-pointer"
        style={{
          width: `${
            Math.floor(Math.min(500, window.innerWidth) - 40) * 0.5 - 10
          }px`,
          height: `${
            Math.floor(Math.min(500, window.innerWidth) - 40) * 0.5 - 10
          }px`,
          borderColor: mainBlackColor,
          color: mainBlackColor,
        }}
        src={
          value === "여"
            ? "/images/icon_woman_sel.png"
            : "/images/icon_woman_unsel.png"
        }
        alt=""
        onClick={() => {
          setValue("여");
          clickNextBtn();
        }}
      />

      <img
        className="hover:cursor-pointer"
        style={{
          width: `${
            Math.floor(Math.min(500, window.innerWidth) - 40) * 0.5 - 10
          }px`,
          height: `${
            Math.floor(Math.min(500, window.innerWidth) - 40) * 0.5 - 10
          }px`,
          borderColor: mainBlackColor,
          color: mainBlackColor,
        }}
        src={
          value === "남"
            ? "/images/icon_man_sel.png"
            : "/images/icon_man_unsel.png"
        }
        alt=""
        onClick={() => {
          setValue("남");
          clickNextBtn();
        }}
      />
    </div>
  );
}

function EditStringPart({
  value,
  setValue,
  clickNextBtn,
}: {
  value: string;
  setValue: (v: string) => void;
  clickNextBtn: () => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const submit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      clickNextBtn();
    }
  };

  return (
    <div className="w-full flex justify-center">
      <TextField
        className="w-full "
        autoFocus={true}
        inputProps={{
          style: {
            height: "50px",
            padding: "0px 10px",
            borderRadius: "10px",
            borderColor: mainGray1Color,
            color: mainGray5Color,
          },
        }}
        value={value}
        onChange={handleChange}
        onKeyDown={submit}
      />
    </div>
  );
}

function EditNumberPart({
  value,
  setValue,
  unit,
  clickNextBtn,
}: {
  value: number;
  setValue: (v: number) => void;
  unit: string;
  clickNextBtn: () => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      !Number.isNaN(Number(event.target.value)) ? Number(event.target.value) : 0
    );
  };
  const submit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      clickNextBtn();
    }
  };

  return (
    <div className="w-full flex items-end">
      <TextField
        className="w-[80%]"
        autoFocus={true}
        inputProps={{
          inputMode: "numeric",
          style: {
            height: "50px",
            padding: "0px 10px",
            borderRadius: "10px",
            borderColor: mainGray1Color,
            color: mainGray5Color,
            fontSize: "20px",
          },
        }}
        value={value}
        onChange={handleChange}
        onKeyDown={submit}
      />
      <div className="w-[20%] flex justify-center items-center text-[20px]">
        {unit}
      </div>
    </div>
  );
}
