import { mainBlackColor, mainWhiteColor } from "../../../const/colors";

export function BasicButton({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) {
  return (
    <div
      style={{
        backgroundColor: mainBlackColor,
        color: mainWhiteColor,
      }}
      className="w-full h-[50px] mt-3 mb-5 rounded-xl flex justify-center items-center hover:cursor-pointer"
      onClick={onClick}
    >
      {text}
    </div>
  );
}
