import { Link } from "react-router-dom";
import { HeaderV2 } from "../HeaderV2";

export default function V2_Used() {
  return (
    <div className="mx-auto max-w-[500px] h-screen flex flex-col items-center px-[20px]">
      <HeaderV2 backRoot={null} />
      <ClubSelPart />
      <SurveyPart />
    </div>
  );
}

function ClubSelPart() {
  return (
    <div className="w-full flex flex-col items-start">
      <div className="text-[25px] mb-4">클럽 선택</div>
      <div className="w-full flex flex-wrap gap-3">
        {OneClubSelBox("드라이버", "driver")}
        {OneClubSelBox("우드", "wood")}
        {OneClubSelBox("아이언", "iron")}
        {OneClubSelBox("웨지", "wedge")}
        {OneClubSelBox("퍼트", "putter")}
      </div>
    </div>
  );
}
function OneClubSelBox(title: string, code: string) {
  return (
    <Link to={`/used/${code}`}>
      <div className="h-[40px] px-[40px] border-2 flex items-center justify-center">
        {title}
      </div>
    </Link>
  );
}
function SurveyPart() {
  return (
    <Link to="/used/survey">
      <div className="w-[200px] h-[40px] flex items-center justify-center border border-red-300 rounded-full text-red-300 my-4">
        골프채 추천 받기
      </div>
    </Link>
  );
}
