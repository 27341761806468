import { Link } from "react-router-dom";

export default function NotFoundScene() {
  return (
    <div className="w-screen h-screen flex flex-col gap-10 items-center justify-center">
      존재하지 않는 페이지 입니다.
      <Link to="/">
        <div className="w-[100px] h-[40px] border border-black ronuded-full flex justify-center items-center rounded-md">
          홈으로 가기
        </div>
      </Link>
    </div>
  );
}
