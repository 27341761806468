import { useParams } from "react-router-dom";
import NotFoundScene from "../../NotFoundScene";
import { HeaderV2 } from "../HeaderV2";
import { useEffect, useState } from "react";
import { Club } from "../../../domain/Club.model";
import { mock_clubs } from "../../../data/mock-data";
export default function ClubInfo() {
  let { code } = useParams();

  const [clubs, setClubs] = useState<Club[]>([]);

  useEffect(() => {
    const thisClubs = mock_clubs
      .filter((v) => v.type === code)
      .map((data) => Club.fromData(data));
    setClubs(thisClubs.map((c: Club) => c.clone));
  }, []);

  function isAbleCode(inputCode: any): boolean {
    if (["driver", "wood", "iron", "wedge", "putter"].includes(inputCode)) {
      return true;
    }
    return false;
  }

  if (!isAbleCode(code)) {
    return <NotFoundScene />;
  }
  return (
    <div className="h-screen flex flex-col items-center">
      <HeaderV2 backRoot={"/used"} />
      {clubs.length === 0 ? <EmptyClubs /> : <ClubsExistPart clubs={clubs} />}
    </div>
  );
}
function EmptyClubs() {
  return <div>정보가 없습니다.</div>;
}
function ClubsExistPart({ clubs }: { clubs: Club[] }) {
  return (
    <div className="flex-1 w-full h-full overflow-y-auto">
      <div className="mx-auto max-w-[500px] w-full flex flex-wrap justify-around gap-y-4">
        {clubs.map((c) => (
          <OneItem c={c} />
        ))}
      </div>{" "}
    </div>
  );
}

function OneItem({ c }: { c: Club }) {
  return (
    <div className="w-[200px] h-[300px] border-2 border-black rounded-lg cursor-pointer">
      <img className="w-[200px] h-[200px] rounded-lg " src={c.imgUrl}></img>
      <div className="w-[200px] h-[40px]">{c.name}</div>
      <div className="w-[200px] h-[30px]">플렉스: {c.flex}</div>
      <div className="w-[200px] h-[30px]">샤프트각도: {c.shaftAngle}</div>
    </div>
  );
}
